import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, Typography, Spin, Card, Avatar } from 'antd';
import { AppstoreOutlined, EllipsisOutlined } from '@ant-design/icons';
import UserAppAPI from '../api/UserAppAPI';
import moment from 'moment';

const { Title, Paragraph } = Typography;

function UserApps() {
    const [userApps, setUserApps] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserApps();
    }, []);

    const fetchUserApps = async () => {
        try {
            const apps = await UserAppAPI.list();
            setUserApps(apps);
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch user apps:', error);
            setLoading(false);
        }
    };

    const handleAppClick = (appId) => {
        navigate(`/user-apps/${appId}/dashboard`);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" />
            </div>
        );
    }

    return (
        <div className="p-4 bg-gray-100 min-h-screen">
            <Title level={2} className="mb-6">Your Apps</Title>
            <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={userApps}
                renderItem={(app) => (
                    <List.Item>
                        <Card
                            hoverable
                            onClick={() => handleAppClick(app.id)}
                            className="w-full bg-white shadow-md transition-shadow duration-300 hover:shadow-lg"
                        >
                            <div className="flex items-center mb-2">
                                {app.logo_url ? (
                                    <Avatar src={app.logo_url} alt={`${app.name} logo`} size={48} className="mr-4" />
                                ) : (
                                    <Avatar icon={<AppstoreOutlined />} size={48} className="mr-4 bg-blue-500" />
                                )}
                                <Title level={4} className="m-0" style={{ marginBottom: 0 }}>{app.name}</Title>
                            </div>
                            <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: <EllipsisOutlined /> }} className="text-gray-500 mb-2">
                                {app.user_description || 'No description'}
                            </Paragraph>
                            <div className="text-xs text-gray-400">
                                Created by: {app.created_by} | {moment(app.created_date).fromNow()}
                            </div>
                        </Card>
                    </List.Item>
                )}
            />
        </div>
    );
}

export default UserApps;