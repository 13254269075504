import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Chat from '../components/chat/Chat';
import AppUserFacingChatAPI from '../api/AppUserFacingChatAPI';
import UserAppAPI from '../api/UserAppAPI';
import { Spin, Alert, Typography, Button } from 'antd';

const { Title } = Typography;

function AppUserFacingChat() {
    const { appId } = useParams();
    const [app, setApp] = useState(null);
    const [chat, setChat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [resetting, setResetting] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleAddMessage = async (message) => {
        try {
            chat.conversation.messages.push(message);
            const updatedChat = await AppUserFacingChatAPI.addMessage(chat.id, message);
            setChat(updatedChat);
        } catch (err) {
            console.error(err);
            setError('Failed to add message: ' + err.message);
        }
    };

    const handleResetChat = async () => {
        setResetting(true);
        try {
            const resetChat = await AppUserFacingChatAPI.resetChat(chat.id);
            setChat(resetChat);
        } catch (err) {
            console.error(err);
            setError('Failed to reset chat: ' + err.message);
        } finally {
            setResetting(false);
        }
    };

    useEffect(() => {
        const fetchChat = async () => {
            setLoading(true);
            try {
                const fetchedApp = await UserAppAPI.get(appId);
                setApp(fetchedApp);
                const fetchedChat = await AppUserFacingChatAPI.getOrCreate(appId);
                await AppUserFacingChatAPI.subscribe(fetchedChat.id, (fetchedChat) => {
                    console.log("num messages", fetchedChat.conversation.messages.length);
                    setChat(fetchedChat);
                });
            } catch (err) {
                console.error(err);
                setError('Failed to load chat: ' + err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchChat();
    }, [appId]);

    if (loading) {
        return <Spin className="flex justify-center items-center h-screen" />;
    }

    if (error) {
        return <Alert message={error} type="error" className="m-4" />;
    }

    if (!chat || !app) {
        return <Alert message="Chat or App not found" type="error" className="m-4" />;
    }

    return (
        <div className={`flex flex-col items-center min-h-screen ${isMobile ? '' : 'p-4 bg-[#f2f1e9]'}`}>
            <div className={`w-full ${isMobile ? 'h-screen' : 'max-w-4xl bg-white rounded-lg shadow-md overflow-hidden'}`}>
                <div className={`flex items-center justify-between ${isMobile ? 'bg-white p-2' : 'bg-white rounded-t-lg p-4'}`}>
                    <div className="flex items-center">
                        {app.logo_url && (
                            <img 
                                src={app.logo_url} 
                                alt={`${app.name} logo`} 
                                className={`${isMobile ? 'w-8 h-8 mr-2' : 'w-12 h-12 mr-4'} rounded-full`}
                            />
                        )}
                        <Title level={isMobile ? 4 : 2} className="m-0" style={{ marginBottom: 0 }}>{app.name}</Title>
                    </div>
                    <Button 
                        onClick={handleResetChat} 
                        disabled={resetting}
                        className={`text-sm ${isMobile ? 'p-1' : ''}`}
                    >
                        {resetting ? <Spin size="small" /> : 'Reset Chat'}
                    </Button>
                </div>
                <div className={`w-full ${isMobile ? 'h-[calc(100%-48px)]' : 'flex-grow'}`}>
                    <Chat chat={chat.conversation} addMessage={handleAddMessage} status={chat.status} />
                </div>
            </div>
        </div>
    );
}

export default AppUserFacingChat;