import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { GoogleOutlined } from '@ant-design/icons';
import { API_BASE_URL } from '../config';
import logoImage from '../logo-v1.png';

export default function Login() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('access_token');
    if (accessToken) {
      localStorage.setItem('token', accessToken);
      const navigateTo = searchParams.get('from_url') || '/';
      window.location.href = navigateTo;
    }
  }, [location]);

  const handleGoogleLogin = () => {
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get('from_url') || '/';
    window.location.href = `${API_BASE_URL}/auth/login?from_url=${fromUrl}`;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-indigo-100">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <div className="flex flex-col items-center mb-8">
          <img src={logoImage} alt="base44 logo" className="w-24 h-24 mb-4" />
          <h2 className="text-3xl font-bold text-center text-gray-800">
            Welcome to base44
          </h2>
        </div>
        <p className="text-center text-gray-600 mb-8">
          Sign in with your Google account to get started
        </p>
        <button
          onClick={handleGoogleLogin}
          className="w-full bg-blue-500 text-white py-3 px-4 rounded-full font-semibold text-lg hover:bg-blue-600 transition duration-300 ease-in-out flex items-center justify-center shadow-md"
        >
          <GoogleOutlined className="mr-2" />
          Continue with Google
        </button>
      </div>
    </div>
  );
}
