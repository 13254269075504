import axios, { AxiosError } from 'axios'
import _ from 'lodash'
import { API_BASE_URL } from '../config'
import socketManager from './socket-manager.ts'

let defaultHeaders = {}

export function addDefaultHeader(key: string, value: string) {
    defaultHeaders[key] = value
}

export function removeDefaultHeader(key: string) {
	delete defaultHeaders[key]
}

let failCallbacks = []

export function addFailCallback(callback: () => void) {
    failCallbacks.push(callback)
}

export default function createClient(endpointBase: string) {
	const axiosClient = axios.create()
	axiosClient.defaults.baseURL = API_BASE_URL + endpointBase
	axiosClient.defaults.headers.common['Content-Type'] = 'application/json'
	axiosClient.defaults.headers.common['Accept'] = 'application/json'

	axiosClient.interceptors.request.use(
		(config: any) => {
            config.headers = _.merge(config.headers, defaultHeaders)
			config.headers['X-Socket-Id'] = socketManager.getSocketId();
			return config
		},
		(error: AxiosError) => {
			return Promise.reject(error)
		},
	)

	axiosClient.interceptors.response.use(
		(response) => {
			return response.data;
		},
		(error: AxiosError) => {
			for (const callback of failCallbacks) {
				callback(error)
			}
			return Promise.reject(error);
		}
	);

	return axiosClient
}
