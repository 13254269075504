import Home from "./HomePage";
import UserSettings from "./UserSettings";
import UserAppDashboard from "./UserAppDashboard";
import UserAppDemo from "./UserAppDemo";
import UserApps from "./UserApps";
import AppUserFacingChat from "./AppUserFacingChat";
import { useParams } from "react-router-dom";
import { HomeOutlined, AppstoreOutlined, EditOutlined, SettingOutlined, CodeOutlined, MessageOutlined } from '@ant-design/icons';

function DashbaordWrapper() {
    const { appId } = useParams();
    return <UserAppDashboard appId={appId} />;
}

const pages = [
    { 
        name: 'Home', 
        url: '/', 
        component: <Home />, 
        icon: <HomeOutlined /> 
    },
    { 
        name: 'User Apps', url: '/user-apps/', component: <UserApps />, icon: <AppstoreOutlined />,
        children: [
            {
                name: 'User App Dashboard', 
                url: '/user-apps/:appId/dashboard', 
                component: <DashbaordWrapper />, 
                icon: <AppstoreOutlined />,
            },
            { 
                name: 'Show User App', 
                url: '/user-apps/:appId/show/:pageName?', 
                component: <UserAppDemo isEditMode={false} />, 
                icon: <CodeOutlined />,
                usesLayout: false
            },
            { 
                name: 'Edit User App', url: '/user-apps/:appId/edit/:pageName', 
                component: <UserAppDemo isEditMode={true} />, 
                icon: <EditOutlined />,
                usesLayout: false
            },
            {
                name: 'App User Facing Chat',
                url: '/user-apps/:appId/chat',
                component: <AppUserFacingChat />,
                icon: <MessageOutlined />,
                usesLayout: false
            }
        ]
    },
    { 
        name: 'User Settings', 
        url: '/user-settings',
        hide: true,
        component: <UserSettings />,
        icon: <SettingOutlined /> },
]

  
export {
    pages
};