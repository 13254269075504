import createClient from './axiosClient.ts'

export default async function createIntegrationsClient(baseUrl, onError) {
    const client = createClient(baseUrl);
    const schema = await client.get('/schema');
    let integrationsClient = {};
    Object.keys(schema).forEach(key => {
        integrationsClient[key] = async (data) => {
            try {
                let formData;
                if (data instanceof FormData || (data && Object.values(data).some(value => value instanceof File))) {
                    formData = new FormData();
                    Object.keys(data).forEach(key => {
                        if (data[key] instanceof File) {
                            formData.append(key, data[key], data[key].name);
                        } else {
                            formData.append(key, data[key]);
                        }
                    });
                }

                const response = await client.post(`/${key}`, formData || data, {
                    headers: formData ? { 'Content-Type': 'multipart/form-data' } : {}
                });
                return response;
            } catch (error) {
                onError(`Error calling integration ${key} with data: ${JSON.stringify(data)}: ${error.message}`);
            }
        }
    });
    return integrationsClient;
}
