import { BaseAPIModelProvider } from '../api/base.ts';

class EntityProvider extends BaseAPIModelProvider {
    constructor(appId, entityName, onError) {
        super(appId, entityName);  
        this.appId = appId;
        this.entityName = entityName;
        // add interceptor to log error 
        this.axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                console.log("ERROR IN ENTITIES!!", error);
                onError(
                    `When using the app, the folloing entity api call failed: 
                    entity: ${this.entityName}
                    url: ${error.config.url}
                    method: ${error.config.method}
                    data: ${error.config.data?.data}
                    error:${error.response?.data?.message || error.message}
                    fulljson:${JSON.stringify(error.response?.data)}`
                    
                );
                return Promise.reject(error);
            }
        );
    }

    parseInstance(instance){
        return {
            id: instance.id,
            created_at: instance.created_at,
            updated_at: instance.updated_at,
            ...instance.data
        };
    }

    getBaseURL() {
        return `/entities`
    }

    
    update(id, data){
        return super.update(id, {data: data});
    }

    create(props){
        return super.create({
            app_id: this.appId,
            entity_name: this.entityName,
            data: props
        });
    }

    list(){
        return super.filter({app_id: this.appId, entity_name: this.entityName});
    }

    filter(query){
        return super.filter({
            app_id: this.appId,
            entity_name: this.entityName,
            ...query
        });
    }
}

export function entitiesFromApp(app, onError){
    let entities = {};
    app.entities.forEach(entity => {
        entities[entity.name] = new EntityProvider(app.id, entity.name, onError);
    })
    return entities;
}