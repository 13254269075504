import React, { useState, useCallback } from 'react';
import { Typography } from 'antd';
import CodeEditor from '../../CodeEditor';
import _ from 'lodash';
const { Title } = Typography;


export default function AppCodeEditor({ code, onSaveCode }) {
    const [localCode, setLocalCode] = useState(code);

    const debouncedSave = useCallback(
        _.debounce((newCode) => {
            onSaveCode(newCode);
        }, 1000),
        [onSaveCode]
    );

    const onChange = (newCode) => {
        setLocalCode(newCode);
        debouncedSave(newCode);
    }

    return (
        <div className="h-full">
            <Title level={4}>Page Code</Title>
            <CodeEditor 
                value={localCode} 
                language='javascript' 
                readonly={false}
                onChange={onChange}
            />
        </div>
    );
}