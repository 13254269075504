import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Form, Input, Button, Typography, message } from 'antd';
import AuthAPI from '../api/AuthAPI';

const { Title } = Typography;

function UserSettings() {
    const { user, updateUser } = useAuth();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onFinish = async (values) => {
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.update(user.id, values);
            updateUser(updatedUser);
            message.success('User settings updated successfully!');
        } catch (error) {
            message.error('Failed to update user settings: ' + error.detail);
        } finally {
            setLoading(false);
        }
    };

    const regenerateApiKey = async () => {
        setLoading(true);
        try {
            const updatedUser = await AuthAPI.regenerateApiKey(user.id);
            updateUser(updatedUser);
            message.success('API key regenerated successfully!');
        } catch (error) {
            message.error('Failed to regenerate API key: ' + error.detail);
        } finally {
            setLoading(false);
        }
    }
    

    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <Title level={2} className="mb-8 text-center">User Settings</Title>
            <div className="max-w-2xl mx-auto">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        username: user.username,
                        email: user.email,
                        fullName: user.full_name,
                        api_key: user.api_key,
                    }}
                >
                    <Form.Item name="username" label="Username">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item name="api_key" label="API Key">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={regenerateApiKey} loading={loading} block>
                            Regenerate API Key
                        </Button>
                    </Form.Item>
                    <Form.Item
                        name="full_name"
                        label="Full Name"
                        rules={[{ required: true, message: 'Please input your full name!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading} block>
                            Save Changes
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

export default UserSettings;
