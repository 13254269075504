import DynamicFormItem from './DynamicFormItem';
import { Form, Button } from 'antd';

const DynamicForm = ({ schema, onSubmit, initialValues }) => {
    const [form] = Form.useForm();
  
    const onFinish = (values) => {
        onSubmit(values);
    };
    const requiredFields = schema.hasOwnProperty("required") ? schema.required : Object.keys(schema.properties).filter(key => !schema.properties[key].hasOwnProperty('default'));
    
  
    return (
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        {
        Object.entries(schema.properties).map(([key, value]) => (
          <DynamicFormItem
                key={key}
                name={[key]}
                schema={value}
                required={requiredFields.includes(key)}
                form={form}
            />
            ))
        }
        <Form.Item>
          <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
    );
  };
  
export default DynamicForm;