import { io } from "socket.io-client";
import _ from "lodash";
import { BACKEND_URL } from "../config.js";

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class SocketManager {
    private _roomToListeners: Record<string, Record<string, Function>>;
    private historicalSocketIds: string[];
    private socket: any;

    constructor() {
        this._roomToListeners = {};
        this.historicalSocketIds = [];

        this.socket = io((BACKEND_URL)!, {
            path: "/ws/socket.io/",
            transports: ['websocket'],
        });

        // client-side
        this.socket.on("connect", () => {
            console.log("connect", this.socket.id); 
            this.historicalSocketIds = _.uniq(this.historicalSocketIds.concat([this.socket.id]));
            this.reconnectAllRooms();
        });

        this.socket.on("disconnect", () => {
            console.log("disconnect", this.socket.id); // undefined
        });

        this.socket.on('update_model', (data: any) => this.onUpdateModel(data));
        this.socket.on('user_joined', (data: any) => {
            console.log("user_joined:", data);
        });

        this.socket.on('user_left', (data: any) => {
            console.log("user_left:", data);
        });

        // catch all
        this.socket.on('*', (data: any) => {
            console.log("catch all:", data);
        });

    }

    getSocketId() {
        return this.socket.id
    }

    reconnectAllRooms() {
        Object.keys(this._roomToListeners).forEach((room) => {
            this.joinRoom(room);
        })
    }

    hasListeners(room: string) {
        return Object.keys(this._roomToListeners[room] || {}).length > 0;
    }

    joinRoom(room: string) {
        this.socket.emit('join', room)
    }

    leaveRoom(room: string) {
        this.socket.emit('leave', room)
    }

    addListener(room: string, func: (data: any) => void) {
        if (!this.hasListeners(room)) {
            this.joinRoom(room);
            this._roomToListeners[room] = {};
        }

        const listenerId = uuidv4();
        this._roomToListeners[room][listenerId] = func;
        const self = this;
        return () => {
            self.removeListener(listenerId)
        }
    }

    getListeners(room: string) {
        return this._roomToListeners[room];
    }

    removeListener(listenerId: string) {
        Object.keys(this._roomToListeners).forEach((room) => {
            delete this._roomToListeners[room][listenerId];
            if (!this.hasListeners(room)) {
                delete this._roomToListeners[room];
                this.leaveRoom(room);
            }
        });
    }

    onUpdateModel(msg: any) {
        let {room, data, sender_sid} = msg;
        if (this.historicalSocketIds.includes(sender_sid)) {
            console.log("skipping own message");
            return;
        }
        data = JSON.parse(data);

        console.log("onUpdateModel", this.socket.id, room, data, "sender sid", sender_sid);

        if (this.hasListeners(room)) {
            Object.keys(this._roomToListeners[room]).forEach((listenerId) => {
                const func = this._roomToListeners[room][listenerId];
                func(data);
            });
        }
    }
}

const socketManager = new SocketManager();



export default socketManager;