import React, { useState, useEffect } from 'react';
import { Typography,  Row, Col, Empty, Button, Input, message, Divider, Spin } from 'antd';
import { PlayCircleOutlined, EnterOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import UserAppAPI from "../api/UserAppAPI";
import AppCard from "../components/user-app/AppCard";
import logoImage from '../logo-v1.png';
import Loader from '../components/Loader';

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const STARTERS = [
    {
        "shortDescription": "TODO list",
        "description": "Create a simple todo list app to manage my tasks. make sure i can separate work and personal tasks." +
            "\nAlso, I want a daily reminder via email to check off tasks that are due that day."
    },
    {
        "shortDescription": "Perplexity clone - LLM Search engine",
        "description": "Create a search engine powered by an LLM, similar to Perplexity. The app should be able to answer questions by searching the internet and providing detailed, contextual responses. Include features like source citations, follow-up questions, and the ability to dive deeper into topics."
    },
    {
        "shortDescription": "Water reminder",
        "description": "Create a water reminder app that sends me a notification to drink water every 3 hours during the day."
    },
    {
        "shortDescription": "CRM for a Tattoo shop",
        "description": "Create a CRM system for a tattoo shop. Include features for managing client information, appointment scheduling, tracking tattoo designs and artists' portfolios. Also, implement a feature for sending appointment reminders and follow-up care instructions via email or SMS."
    },
    {
        "shortDescription": "GPU marketplace",
        "description": "Create a marketplace for buying and selling used GPUs. Include features for filtering GPUs by brand, model, price, and other specifications. Also, implement a feature for sending notifications when new GPUs matching my search criteria are available."
    },
    {
        "shortDescription": "Split expenses app",
        "description": "Create a split expenses app for a group of friends. Include features for managing expenses, tracking who owes whom, and splitting bills evenly or by percentage. Also, implement a feature for sending reminders and requesting payments from friends."
    }
]


const AppConversationStarter = ({ onStart, loading }) => {
    const [appDescription, setAppDescription] = useState('');

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            const trimmedDescription = appDescription.trim();
            if (trimmedDescription) {
                onStart(trimmedDescription);
                setAppDescription('');
            }
        }
    };

    const handleBubbleClick = (description) => {
        setAppDescription(description);
    };

    return (
        <div className="mb-6">
            <div className="relative">
                <TextArea
                    rows={4}
                    value={appDescription}
                    onChange={(e) => setAppDescription(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Describe the app you want to create..."
                    className="mb-4 text-base w-full pr-12"
                />
                {appDescription.trim() && (
                    <div className="absolute right-3 bottom-6 text-gray-400 flex items-center">
                        <EnterOutlined className="mr-1" />
                        <span className="text-sm">Press Enter to create</span>
                    </div>
                )}
            </div>
            <div className="flex flex-wrap gap-2 mb-4">
                {STARTERS.map((starter, index) => (
                    <button
                        key={index}
                        className="px-3 py-2 bg-white rounded-full text-sm hover:bg-teal-100 hover:text-teal-700 transition-colors duration-200"
                        onClick={() => handleBubbleClick(starter.description)}
                    >
                        {starter.shortDescription}
                    </button>
                ))}
            </div>
        </div>
    );
};

function Home() {
    const [apps, setApps] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        fetchApps();
    }, []);

    const fetchApps = async () => {
        setIsLoading(true);
        try {
            const appList = await UserAppAPI.list();
            setApps(appList.slice(0, 20)); // Get the 20 most recent apps
        } catch (error) {
            console.error('Failed to fetch apps:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateApp = async (appDescription) => {
        if (!appDescription.trim()) {
            message.error('Please enter an app description');
            return;
        }
        setIsCreating(true);
        try {
            const newApp = await UserAppAPI.create({
                status: {state: "processing", details: "Starting conversation..."}
            });
            UserAppAPI.addMessage(newApp.id, {content: appDescription});
            navigate(`/user-apps/${newApp.id}/dashboard`);
        } catch (error) {
            console.error('Failed to create app:', error);
            message.error('Failed to create app. Please try again.');
        } finally {
            setIsCreating(false);
        }
    };

    return (
        <div className="site-card-wrapper p-4 md:p-8 max-w-4xl mx-auto">
            <div className="text-center">
                <Title level={1} className="text-3xl md:text-5xl mb-4">Welcome to Base44</Title>
                <Paragraph className="text-lg md:text-l text-gray-500">
                    Create beautiful, useful apps with ease. 
                </Paragraph>
            </div>

            <Divider />
            
            <div className="p-6 rounded-lg mb-12">
                <Title level={3} className="mb-4 text-2xl flex items-center">
                    <img src={logoImage} alt="Base44 Logo" className="mr-2 h-8 w-auto" /> Create New App
                </Title>

                <AppConversationStarter onStart={handleCreateApp} loading={isCreating} />
                
            </div>

            <Title level={3} className="mb-6 text-2xl">Your Recent Apps</Title>
            
            {isLoading ? (
                <div className="text-center">
                    <Spin size="large" />
                </div>
            ) : apps.length > 0 ? (
                <Row gutter={[24, 24]}>
                    {apps.map((app, index) => (
                        <Col xs={24} sm={12} md={8} key={index}>
                            <AppCard app={app} />
                        </Col>
                    ))}
                </Row>
            ) : (
                <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={
                        <span className="text-base md:text-lg">
                            You haven't created any apps yet. Start by creating your first app above!
                        </span>
                    }
                />
            )}
        </div>
    );
}

export default Home;
