import React, { useState, useEffect } from 'react';
import { Card, Table, Button, message, Typography, Space, Input, Modal, Image, Divider } from 'antd';
import { UserOutlined, MailOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import UserAppAPI from '../../api/UserAppAPI';
import InviteUserModal from './InviteUserModal';
import { useNavigate, Link } from 'react-router-dom';

const { Text } = Typography;
const { confirm } = Modal;

const AppSettings = ({ app }) => {
  const [users, setUsers] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [appName, setAppName] = useState(app.name);
  const [appDescription, setAppDescription] = useState(app.user_description);
  const [isUpdateNameOrDescription, setIsUpdateNameOrDescription] = useState(false);
  const [isGeneratingLogo, setIsGeneratingLogo] = useState(false);
  const [userFacingChatPrompt, setUserFacingChatPrompt] = useState(app.user_facing_chat_system_prompt || '');
  const [isUpdatingChatPrompt, setIsUpdatingChatPrompt] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUsers();
  }, [app.id]);

  const fetchUsers = async () => {
    try {
      const response = await UserAppAPI.listUsers(app.id);
      setUsers(response);
    } catch (error) {
      message.error('Failed to fetch users');
    }
  };

  const columns = [
    {
      title: 'User',
      key: 'user',
      render: (_, record) => (
        <Space direction="vertical" size="small">
          <Text><UserOutlined className="mr-2" />{record.name}</Text>
          <Text type="secondary"><MailOutlined className="mr-2" />{record.email}</Text>
        </Space>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (text) => (
        <span className={`px-2 py-1 rounded ${text === 'admin' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`}>
          {text}
        </span>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="link"
          onClick={() => handleRoleChange(record)}
          disabled={record.role === 'admin'}
        >
          Make Admin
        </Button>
      ),
    },
  ];

  const handleRoleChange = async (user) => {
    try {
      await UserAppAPI.updateUserRole(app.id, user.email, 'admin');
      message.success(`${user.email} is now an admin`);
      fetchUsers();
    } catch (error) {
      message.error('Failed to update user role');
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleInviteSuccess = () => {
    fetchUsers();
  };

  const handleNameChange = (e) => {
    setAppName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setAppDescription(e.target.value);
  };

  const handleUpdateApp = async () => {
    try {
      setIsUpdateNameOrDescription(true);
      await UserAppAPI.update(app.id, { name: appName, description: appDescription });
      message.success('App details updated successfully');
    } catch (error) {
      message.error('Failed to update app details');
    } finally {
      setIsUpdateNameOrDescription(false);
    }
  };

  const handleRegenerateLogo = async () => {
    try {
      setIsGeneratingLogo(true);
      await UserAppAPI.generateLogo(app.id);
      message.success('Logo regenerated successfully');
    } catch (error) {
      message.error('Failed to regenerate logo');
    } finally {
      setIsGeneratingLogo(false);
    }
  };

  const handleUserFacingChatPromptChange = (e) => {
    setUserFacingChatPrompt(e.target.value);
  };

  const handleUpdateUserFacingChatPrompt = async () => {
    try {
      setIsUpdatingChatPrompt(true);
      await UserAppAPI.update(app.id, { user_facing_chat_system_prompt: userFacingChatPrompt });
      message.success('User facing chat prompt updated successfully');
    } catch (error) {
      message.error('Failed to update user facing chat prompt');
    } finally {
      setIsUpdatingChatPrompt(false);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this app?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        handleDeleteApp();
      },
    });
  };

  const handleDeleteApp = async () => {
    try {
      await UserAppAPI.delete(app.id);
      message.success('App deleted successfully');
      navigate('/');
    } catch (error) {
      message.error('Failed to delete app');
    }
  };

  return (
    <div className="min-h-screen">
      <Card 
        title={<h2 className="text-2xl font-bold">App Settings</h2>}
        className="mb-6 shadow-md"
      >
        <h3 className="text-xl font-semibold mb-4">App Details</h3>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Input
            addonBefore="Name"
            value={appName}
            onChange={handleNameChange}
          />
          <Input.TextArea
            rows={4}
            value={appDescription}
            onChange={handleDescriptionChange}
            placeholder="App Description"
          />
          <Button onClick={handleUpdateApp} loading={isUpdateNameOrDescription}>Update App Details</Button>
        </Space>

        <Divider />

        <h3 className="text-xl font-semibold my-4">App Logo</h3>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {app.logo_url && (
            <Image
              src={app.logo_url}
              alt="App Logo"
              width={200}
            />
          )}
          <Button onClick={handleRegenerateLogo} loading={isGeneratingLogo}>Regenerate Logo</Button>
        </Space>

        <Divider />

        <h3 className="text-xl font-semibold my-4">User Management</h3>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Button type="primary" onClick={showModal}>Invite User</Button>
          <Table 
            columns={columns} 
            dataSource={users}
            rowKey="id"
            pagination={false}
            className="bg-white rounded-lg shadow"
            scroll={{ x: 'max-content' }}
          />
        </Space>

        <Divider />

        <h3 className="text-xl font-semibold my-4">User Facing Chat</h3>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <Input.TextArea
            rows={6}
            value={userFacingChatPrompt}
            onChange={handleUserFacingChatPromptChange}
            placeholder="Enter the system prompt for user facing chat"
          />
          <Button onClick={handleUpdateUserFacingChatPrompt} loading={isUpdatingChatPrompt}>
            Update User Facing Chat Prompt
          </Button>
          <Link to={`/user-apps/${app.id}/chat`} target="_blank" rel="noopener noreferrer">
            <Button type="link">Open Chat in New Tab</Button>
          </Link>
        </Space>

        <Divider />

        <h3 className="text-xl font-semibold my-4 text-red-600">Danger Zone</h3>
        <Button danger onClick={showDeleteConfirm}>Delete App</Button>
      </Card>

      <InviteUserModal
        isVisible={isModalVisible}
        onCancel={handleCancel}
        appId={app.id}
        onInviteSuccess={handleInviteSuccess}
      />
    </div>
  );
};

export default AppSettings;
