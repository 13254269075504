import './index.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Layout, Spin, ConfigProvider } from 'antd';
import { AuthProvider, useAuth } from './context/AuthContext.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import PageNotFound from './pages/PageNotFound.js';
import { pages } from './pages/index.js';
import Sidebar from './components/Sidebar.js';
import antdTheme from './theme/antdTheme.ts';
import _ from 'lodash';
import './App.css'
import { BACKEND_URL } from './config.js';
import { useParams } from 'react-router-dom';
import Loader from './components/Loader.js';

const { Content } = Layout;

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoadingAuth } = useAuth();

  if (isLoadingAuth) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    );
  }
  
  if (!isAuthenticated) {
    // return <h1>"Not authenticated"</h1>
    const currentUrl = encodeURIComponent(window.location.href);
    return <Navigate to={`/login?from_url=${currentUrl}`} replace />;
  }
  
  return children;
};

function NavigateToFile(){
  const { fileId } = useParams();
  window.location.href = `${BACKEND_URL}/api/files/${fileId}`;
}

const App = () => {
  const flatPages = _.flatMapDeep(pages, page => [page, ...(page.children || [])]);
  return (
    <ConfigProvider theme={antdTheme}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            {flatPages.map(p => 
              p.usesLayout === false ? (
                <Route key={p.url} path={p.url} element={<ProtectedRoute>{p.component}</ProtectedRoute>} />
              ) : (
                <Route key={p.url} path={p.url} element={
                  <Layout className="layout h-screen flex">
                    <Sidebar pages={pages} className="fixed h-full overflow-y-auto" />
                    <Layout> 
                      <Content className="p-2 md:p-12 mt-16 md:mt-5 overflow-y-auto h-full">
                        <ProtectedRoute>{p.component}</ProtectedRoute>
                      </Content>
                    </Layout>
                  </Layout>
                } />
              )
            )}
            <Route path="/api/files/:fileId" element={<NavigateToFile />} replace />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
};

export default App;