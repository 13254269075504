import { BaseAPIModelProvider } from './base.ts'

class _AppUserFacingChatAPI extends BaseAPIModelProvider {
    modelName = 'user_facing_chat'

    getBaseURL() {
        return '/app-user-facing-chats'
    }

    createFromMessage(msg) {
        return this.axios.post('/chat/create-from-message', msg)
    }

    addMessage(itemId, message) {
        return this.axios.post(`/${itemId}/chat/add-message`, message)
    }

    getOrCreate(appId) {
        return this.axios.post(`/${appId}/get-or-create`)
    }

    resetChat(itemId) {
        return this.axios.post(`/${itemId}/chat/reset`)
    }
}

const AppUserFacingChatAPI = new _AppUserFacingChatAPI();

export default AppUserFacingChatAPI;