import React from 'react';
import { Alert, Typography, Button } from 'antd';

const { Title } = Typography;


export default function AppErrors({ errors, trySolveError }) {
    return (
        <div className="h-full">
            <Title level={4}>Errors</Title>
            {errors.map((error, index) => (
                <Alert
                    key={index}
                    message={error.toString()}
                    type="error"
                    className="mb-4"
                    action={
                        <Button onClick={() => trySolveError(error)} type="primary" size="small">
                            Try to solve
                        </Button>
                    }
                />
            ))}
        </div>
    );
}

