import createClient from './axiosClient.ts'
import { AxiosInstance } from 'axios'
import socketManager from './socket-manager.ts'


export class BaseAPIProvider {
	axios: AxiosInstance

	constructor() {
		this.axios = this.getClient()
	}

	getBaseURL(): string {
		return '/'
	}

	getClient(): AxiosInstance {
		return createClient(this.getBaseURL())
	}
}

export interface BaseAPIModel {
	id: string
	name: string
	created_date: string
	updated_date: string
	description?: string
}

export class BaseAPIModelProvider<T extends BaseAPIModel = BaseAPIModel> extends BaseAPIProvider {
	parseInstance(instance: T): T {
		return instance
	}

	list(): Promise<T[]> {
		return this.axios.get().then((res) => res.map(this.parseInstance))
	}

	filter(query: Record<string, any>): Promise<T[]> {
		return this.axios.get('', { params: query }).then((res) => res.map(this.parseInstance))
	}

	get(id: string): Promise<T> {
		return this.axios.get(`/${id}`).then(this.parseInstance)
	}

	async subscribe(id: string, callback: (data: T) => void): Promise<() => void>{
		const rawValues = await this.get(id);
		callback(this.parseInstance(rawValues));
		
		const room = this.getBaseURL() + '/' + id;
		return socketManager.addListener(room, (data) => {
			callback(
				this.parseInstance({
					...rawValues,
					...data
				})
			)
		})
	}

	getSchema(): Promise<Record<string, any>> {
		return this.axios.get('/schema')
	}

	create(props: Omit<T, 'id' | 'created_date' | 'updated_date'>): Promise<T> {
		return this.axios.post('', props).then(this.parseInstance)
	}

	update(id: string, props: Partial<T>): Promise<T> {
		return this.axios.put(`/${id}`, props).then(this.parseInstance).then((res) => {
			const listeners = socketManager.getListeners(this.getBaseURL() + '/' + id) || [];
			console.log("calling listeners", listeners, res);
			Object.values(listeners).forEach((listener) => listener(res));
			return res
		});
	}

	delete(id: string): Promise<void> {
		return this.axios.delete(`/${id}`)
	}
}

export interface PoliymorphicAPIModel extends BaseAPIModel {
	type: string
	[key: string]: any
}

export class BasePolyMorphicAPIModelProvider<
	T extends PoliymorphicAPIModel = PoliymorphicAPIModel,
> extends BaseAPIModelProvider<T> {
	getTypeSchema(type: string) {
		return this.axios.get(`/${type}/schema`)
	}

	createType(type: string, props: Omit<T, 'type'>): Promise<T> {
		const payload = { type, ...props } as T
		return super.create(payload)
	}
}
