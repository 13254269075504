import React, { useState } from 'react';
import { Alert, Typography, Checkbox, Button, Input,  List } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import html2canvas from 'html2canvas';

const { Title } = Typography;
const { TextArea } = Input;

export default function ChangesChat({ makeChanges, checkpoints, loadCheckpoint }) {
    const [chatInput, setChatInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [shouldCaptureScreenshot, setShouldCaptureScreenshot] = useState(false);

    const captureScreenshot = async () => {
        const componentPreview = document.getElementById('component-preview');
        const canvas = await html2canvas(componentPreview)
        const screenshot = canvas.toDataURL();
        return screenshot;
    }

    const handleChatSubmit = async () => {
        setIsLoading(true);
        setError(null);
        let image = null;
        if (shouldCaptureScreenshot) {
            image = await captureScreenshot();
        }
        try {
            await makeChanges(chatInput, image);
            setChatInput('');
        } catch (err) {
            setError('Failed to make changes: ' + err.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="h-full flex flex-col">
            <Title level={4}>Changes History</Title>
            <List
                size="small"
                dataSource={checkpoints}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <Button 
                                icon={<HistoryOutlined />} 
                                onClick={() => loadCheckpoint(item.id)}
                            >
                                Load
                            </Button>
                        ]}
                    >
                        {item.changes}
                    </List.Item>
                )}
                style={{ marginBottom: '20px', maxHeight: '200px', overflowY: 'auto' }}
            />
            <Title level={4}>Ask for changes</Title>
            {error && <Alert message={error} type="error" className="mb-4" />}
            <TextArea
                rows={7}
                value={chatInput}
                onChange={(e) => setChatInput(e.target.value)}
                placeholder="Type your change request here..."
                className="mb-4"
                style={{ resize: 'none' }}
            />
            <Checkbox onChange={() => setShouldCaptureScreenshot(!shouldCaptureScreenshot)}>Capture Screenshot</Checkbox>
            <Button type="primary" onClick={handleChatSubmit} loading={isLoading} block>
                Submit Changes
            </Button>
        </div>
    );
}


