import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Layout, Menu, Avatar, Tooltip, Drawer, Button } from 'antd';
import { UserOutlined, SettingOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import logoImage from '../logo-v1.png';

const { Sider } = Layout;

const Sidebar = ({ pages }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState(false);

  const handleMenuClick = (key) => {
    navigate(key);
    setMobileDrawerVisible(false);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    setMobileDrawerVisible(false);
  };

  const menuItems = pages.filter(page => !page.hide).map((page) => ({
    key: page.url,
    label: (
      <div className="flex items-center justify-center w-full h-full">
        <Tooltip placement="right" title={page.name}>
          {React.cloneElement(page.icon, { style: { fontSize: '24px' } })}
        </Tooltip>
        <span className="ml-3 md:hidden">{page.name}</span>
      </div>
    ),
    onClick: () => handleMenuClick(page.url),
  }));

  const userSubMenuItems = [
    {
      key: 'settings',
      icon: <SettingOutlined style={{ fontSize: '24px' }} />,
      label: 'Settings',
      onClick: () => {
        navigate('/user-settings');
        setMobileDrawerVisible(false);
      },
    },
    {
      key: 'logout',
      icon: <LogoutOutlined style={{ fontSize: '24px' }} />,
      label: 'Sign Out',
      onClick: handleLogout,
    },
  ];

  const SidebarContent = () => (
    <>
      <div className="h-16 flex justify-center items-center bg-[#001529]">
        <img src={logoImage} alt="Base44 Logo" className="h-12 w-auto" />
      </div>
      <Menu
        mode="vertical"
        className="h-[calc(100%-4rem)] border-r-0 flex flex-col items-center pt-4"
        items={menuItems}
        selectedKeys={[location.pathname]}
        theme="dark"
      />
      <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2">
        <Menu
          mode="vertical"
          items={[
            {
              key: 'user',
              icon: (
                <Tooltip title={user?.email || 'User'}>
                  <Avatar 
                    icon={<UserOutlined style={{ fontSize: '24px' }} />} 
                    size={40}
                  />
                </Tooltip>
              ),
              children: userSubMenuItems,
            },
          ]}
          theme="dark"
        />
      </div>
    </>
  );

  return (
    <>
      {/* Mobile Menu Button */}
      <Button
        className="fixed top-4 left-4 z-50 md:hidden"
        icon={<MenuOutlined />}
        onClick={() => setMobileDrawerVisible(true)}
      />

      {/* Mobile Drawer */}
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setMobileDrawerVisible(false)}
        open={mobileDrawerVisible}
        styles={{
          body: { padding: 0, backgroundColor: '#001529' }
        }}
        width={250}
      >
        <SidebarContent />
      </Drawer>

      {/* Desktop Sidebar */}
      <Sider width={110} className="hidden md:block">
        <SidebarContent />
      </Sider>
    </>
  );
};

export default Sidebar;
