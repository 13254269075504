import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spin, Alert, Layout, Button, Tabs, Drawer, Tooltip, message, Modal } from 'antd';
import UserAppAPI, { UserAppHistoryAPI } from '../api/UserAppAPI';
import ComponentPreview from '../components/ComponentPreview';
import { CloseOutlined, MenuOutlined, ArrowLeftOutlined, EditOutlined, ShareAltOutlined } from '@ant-design/icons';
import AppErrors from '../components/user-app/user-app-sidebar/AppErrors';
import AppCodeEditor from '../components/user-app/user-app-sidebar/AppCodeEditor';
import ChangesChat from '../components/user-app/user-app-sidebar/ChangesChat';
import { useMediaQuery } from 'react-responsive';
import Loader from '../components/Loader';
import DynamicForm from '../components/dynamic-form/DynamicForm';
import { AppProvider, useApp } from '../context/AppContext';


const { Content } = Layout;
const { TabPane } = Tabs;

function AppSideBar({selectedPage}) {
    const { app, setApp, appErrors } = useApp();
    const [checkpoints, setCheckpoints] = useState([]);
    const [activeTab, setActiveTab] = useState('1');

    const makeChanges = async (changes, image) => {
        const updatedApp = await UserAppAPI.editPageCode(app.id, selectedPage.id, changes, image);
        setApp(updatedApp);
        fetchCheckpoints();
        window.location.reload();
    }

    const loadCheckpoint = async (checkpointId) => {
        const updatedApp = await UserAppAPI.loadCheckpoint(app.id, checkpointId);
        setApp(updatedApp);
    }

    const fetchCheckpoints = useCallback(async () => {
        const history = await UserAppHistoryAPI.filter({ app_id: app.id });
        setCheckpoints(history);
    }, [app.id]);

    useEffect(() => {
        fetchCheckpoints();
    }, [fetchCheckpoints]);

    const trySolveError = (error) => {
        const errorMessage = error.toString();
        const prompt = `I encountered the following error in my React component: "${errorMessage}". Please edit the code to fix this error.`;
        makeChanges(prompt); //.then(() => {
            // setAppErrors(appErrors.filter(e => e !== error));
        // });
    }


    const manualEditPageCode = async (code) => {
        const updatedApp = await UserAppAPI.manualEditPageCode(app.id, selectedPage.id, code);
        setApp(updatedApp);
        fetchCheckpoints();
    }

    return <Tabs activeKey={activeTab} onChange={setActiveTab}>
    <TabPane tab="Changes" key="1">
        <ChangesChat 
            makeChanges={makeChanges} 
            checkpoints={checkpoints} 
            loadCheckpoint={loadCheckpoint} />
    </TabPane>
    <TabPane tab="Errors" key="2">
        <AppErrors errors={appErrors} trySolveError={trySolveError} />
    </TabPane>
    <TabPane tab="Code" key="3">
        <AppCodeEditor 
            code={selectedPage.code} 
            onSaveCode={manualEditPageCode}
        />
    </TabPane>
</Tabs>


}

function UserAppInternal({currentPage}) {
    const { app, setApp, entitiesSDK, userInfo, updateUserInfo, integrations, appErrors, onAppError } = useApp();
    const navigate = useNavigate();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [isFirstVisit, setIsFirstVisit] = useState(true);
    const [compiledCode, setCompiledCode] = useState(null);
    const [compileError, setCompileError] = useState(null);

    const selectedPage = app.pages.find(page => page.name.toLowerCase() === currentPage.toLowerCase());
    useEffect(() => {
        if (app) {
            const compileCode = async () => {
                try {
                    const code = await UserAppAPI.compilePageCode(app.id, selectedPage.id);
                    setCompiledCode(code);
                } catch (error) {
                    setCompileError(error);
                }
            };
            compileCode();
        }
    }, [app, selectedPage]);

    useEffect(() => {
        const hasVisited = localStorage.getItem('hasVisitedUserAppDemo');
        if (!hasVisited) {
            localStorage.setItem('hasVisitedUserAppDemo', 'true');
        } else {
            setIsFirstVisit(false);
        }
    }, []);



    const parseUrlParameters = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const params = {};
        urlParams.forEach((value, key) => {
            params[key] = value;
        });
        return params;
    }

    const urlParams = parseUrlParameters();
    const navigateToPage = (page, urlParams) => {
        // using window because we need to reload the page, because of the same component render bug in ComponentPreview
        if (urlParams) {
            window.location.href = `/user-apps/${app.id}/show/${page}?${new URLSearchParams(urlParams).toString()}`;
        } else {
            window.location.href = `/user-apps/${app.id}/show/${page}`;
        }
    }
    
    const handleShareApp = () => {
        const currentUrl = window.location.href;
        navigator.clipboard.writeText(currentUrl).then(() => {
            message.success('App link copied to clipboard!');
        }).catch(err => {
            console.error('Failed to copy: ', err);
            message.error('Failed to copy app link');
        });
    };

    // TODO: this is dupliicate, move this to AppSideBar
    const makeChanges = async (changes, image) => {
        const updatedApp = await UserAppAPI.editPageCode(app.id, currentPage, changes, image);
        setApp(updatedApp);
        // refresh the page
        window.location.reload();
    }

    if (!selectedPage) {
        return <Alert message="Page not found" type="error" />;
    }

    if (compileError) {
        console.log("COMPILE ERROR", compileError);
        return <Alert message={compileError.response.data.detail || compileError.message} type="error" />;
    }


    return (
        <Layout className="min-h-screen">            
            <Layout>
                <Content className="" id="component-preview">
                    {app.status?.state === "processing" || !compiledCode ? (
                        <div className="fixed inset-0 flex justify-center items-center w-screen h-screen bg-slate-600">
                            <Loader />
                        </div>
                    ) : (
                        <ComponentPreview
                            componentCode={compiledCode}
                            componentName={selectedPage.name}
                            componentProps={{ 
                                entities: entitiesSDK, 
                                navigateToPage: navigateToPage, 
                                user: userInfo,
                                updateUserData: updateUserInfo,
                                integrations: integrations,
                                onError:onAppError,
                                urlParams: urlParams
                            }}
                            makeChanges={makeChanges}
                            showEditor={false}
                        />
                    )}
                </Content>
                {sidebarVisible && !isMobile && (
                    <Layout.Sider 
                        // width={activeTab === '3' ? 600 : 300} 
                        width={450}
                        style={{
                            padding: '16px',
                            backgroundColor: 'white',
                            borderLeft: '1px solid #d9d9d9'
                        }}
                    >
                        <AppSideBar selectedPage={selectedPage} />
                    </Layout.Sider>
                )}
                {sidebarVisible && isMobile && (
                    <Drawer
                        placement="right"
                        closable={true}
                        onClose={() => setSidebarVisible(false)}
                        open={sidebarVisible}
                        width="100%"
                    >
                        <AppSideBar selectedPage={selectedPage} />
                    </Drawer>
                )}
            </Layout>
            {userInfo.role === "admin" && (
                <>
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<ArrowLeftOutlined />}
                        size="large"
                        onClick={() => navigate(`/user-apps/${app.id}/dashboard`)}
                        className="fixed bottom-8 right-48 w-15 h-15 flex justify-center items-center shadow-lg transition-all duration-300 ease-in-out hover:scale-110 z-50"
                    />
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<ShareAltOutlined />}
                        size="large"
                        onClick={handleShareApp}
                        className="fixed bottom-8 right-28 w-15 h-15 flex justify-center items-center shadow-lg transition-all duration-300 ease-in-out hover:scale-110 z-50"
                    />
                    <Tooltip title={isFirstVisit ? "Click here to edit this page!" : ""} open={isFirstVisit}>
                        <Button
                            id="edit-button"
                            type={appErrors.length > 0 ? "danger" : "primary"}
                            shape="circle"
                            icon={sidebarVisible ? (isMobile ? <MenuOutlined /> : <CloseOutlined />) : <EditOutlined />}
                            size="large"
                            onClick={() => setSidebarVisible(!sidebarVisible)}
                            className={`fixed bottom-8 right-8 w-15 h-15 flex justify-center items-center shadow-lg transition-all duration-300 ease-in-out hover:scale-110 z-50 ${isFirstVisit ? 'animate-pulse' : ''}`}
                        />
                    </Tooltip>
                </>
            )}
        </Layout>
    );
}

function UserDataFormModal({ app, userInfo, onSubmit }) {
    return <Modal
        title="Fill in required user data"
        open={true}
        footer={null}>
        <DynamicForm schema={app.additional_user_data_schema} initialValues={userInfo.app_data} onSubmit={onSubmit} />
    </Modal>;
}

function UserAppDemo({ isEditMode }) {
    const { pageName } = useParams();
    const { app, setApp, userInfo, updateUserInfo } = useApp();
    const [showUserDataFormModal, setShowUserDataFormModal] = useState(false);

    function shouldShowUserDataFormModal(app, userInfo) {
        if (!app || !userInfo) {
            return false;
        }
        const requiredFields = app.required_user_data_fields;
        if (!requiredFields || !userInfo || !userInfo.app_data) {
            return false;
        }

        const missingFields = requiredFields.filter(field => !userInfo.app_data.hasOwnProperty(field) || userInfo.app_data[field] === null || userInfo.app_data[field] === undefined);
        if (missingFields.length > 0) {
            console.log("MISSING FIELDS", missingFields, "showing form modal");
        }
        return missingFields.length > 0;
    }

    useEffect(() => {
        if (shouldShowUserDataFormModal(app, userInfo)) {
            setShowUserDataFormModal(true);
        } else {
            setShowUserDataFormModal(false);
        }
    }, [app, userInfo]);

    if (showUserDataFormModal) {
        console.log("USER INFI", userInfo);
        return <UserDataFormModal 
            app={app}
            userInfo={userInfo}
            onSubmit={(userData) => updateUserInfo(userInfo.id, userData)}
        />;
    }

    return (
        <UserAppInternal 
            app={app}
            currentPage={pageName || app.pages[0].name}
            setApp={setApp}
            isEditMode={isEditMode}
            userInfo={userInfo}
            updateUserInfo={updateUserInfo}
        />
    );
}

export default () => <AppProvider>
    <UserAppDemo />
</AppProvider>;