// import { theme } from 'antd';

// const primaryColor = '#20B2AA'; // lightseagreen

// const antdTheme = {
//   token: {
//     colorPrimary: primaryColor,
//     colorSuccess: '#52c41a',
//     colorWarning: '#faad14',
//     colorError: '#ff4d4f',
//     colorInfo: primaryColor,

//     // Derived colors
//     colorPrimaryBg: '#e6f7f5',
//     colorPrimaryBgHover: '#b5e8e3',
//     colorPrimaryBorder: '#87ded7',
//     colorPrimaryBorderHover: '#4dccc2',
//     colorPrimaryHover: '#1a8e88',
//     colorPrimaryActive: '#147570',
//     colorPrimaryTextHover: '#1a8e88',
//     colorPrimaryText: '#20B2AA',
//     colorPrimaryTextActive: '#147570',

//     // Neutral colors
//     colorTextBase: '#333333',
//     colorTextSecondary: '#666666',
//     colorTextTertiary: '#999999',
//     colorBgContainer: '#ffffff',
//     colorBorder: '#d9d9d9',
//     colorBorderSecondary: '#f0f0f0',

//     // Component specific
//     colorLink: '#1a8e88',
//     colorLinkHover: '#4dccc2',
//     colorLinkActive: '#147570',

//     // Font
//     fontFamily: "'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif",
//     fontSize: 14,

//     // Border radius
//     borderRadius: 4,

//     // Animation
//     motionDurationMid: '0.2s',
//     motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
//   },
//   algorithm: theme.defaultAlgorithm,
// };

// export default antdTheme;
const theme = {
  token: {
    // Colors
    colorPrimary: '#00A693', // Persian Green
    colorPrimaryBg: '#E0FFFF', // Light Cyan (background)
    colorPrimaryBgHover: '#B0E0E0', // Slightly darker Light Cyan for hover states
    colorPrimaryBorder: '#008B8B', // Darker Persian Green for borders
    colorPrimaryText: '#36454F', // Charcoal for primary text
    colorPrimaryTextHover: '#2F4F4F', // Darker Charcoal for text hover
    colorPrimaryTextActive: '#1C2841', // Even darker Charcoal for active text

    colorSecondary: '#F4C430', // Saffron
    colorSecondaryBg: '#FFEFD5', // Lighter Saffron for secondary backgrounds
    colorSecondaryBorder: '#E49B0F', // Darker Saffron for borders

    colorSuccess: '#4CAF50', // A green color for success states
    colorWarning: '#FFA500', // Sandy Brown for warnings
    colorError: '#E25822', // Burnt Sienna for errors
    colorInfo: '#00CED1', // A shade of cyan for info states

    // Typography
    fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: 14,
    
    // Border Radius
    borderRadius: 4,

    // Spacing
    padding: 16,
    margin: 16,

    // Other customizations
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    colorBgContainer: '#FFFFFF',
    colorTextBase: '#333333',
  },
  components: {
    Layout: {
      siderBg: '#839694',  // Light gray background for sidebar
      headerBg: '#839694',
      triggerBg: '#002140', // Dark blue for the collapse trigger
      zeroTriggerBg: '#002140',
    },
    Menu: {
      itemBg: '#f0f2f5',  // Light gray background for menu items
      itemSelectedBg: '#1890ff',  // Blue background for selected item
      itemSelectedColor: '#ffffff',  // White text for selected item
    },
  }
};

export default theme;