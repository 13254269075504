import { useState } from 'react';
import { Button } from 'antd';
import UserAppAPI  from '../../api/UserAppAPI';

export default function RegisterToApp({ appId, appName, appDescription, onRegister, logoUrl }) {
    const [loading, setLoading] = useState(false);

    const handleRegister = async () => {
        setLoading(true);
        try {
            await UserAppAPI.registerUser(appId);
            onRegister();
        } catch (error) {
            console.error('Failed to register:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gradient-to-r from-blue-100 to-purple-100">
            <div className="bg-white p-10 rounded-xl shadow-lg max-w-md w-full text-center">
                {logoUrl && (
                    <img src={logoUrl} alt={`${appName} logo`} className="w-24 h-24 mx-auto mb-6 rounded-full shadow-md" />
                )}
                <h1 className="text-3xl font-bold mb-4 text-indigo-700">
                    Welcome to {appName}
                </h1>
                <p className="mb-6 text-gray-600">{appDescription}</p>
                <p className="mb-8 text-sm text-gray-500">Join now to access exclusive features and content!</p>
                <Button 
                    type="primary" 
                    onClick={handleRegister}
                    size="large"
                    className="bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300 text-white font-semibold py-2 px-6 rounded-full shadow-md hover:shadow-lg"
                    loading={loading}
                >
                    {loading ? 'Registering...' : 'Join Now'}
                </Button>
            </div>
        </div>
    );
}