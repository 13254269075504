import React, { useState, useEffect } from 'react';
import { Card, Typography, Tabs, Button, Modal, Spin, List, Tag, Space, message, Empty, Input } from 'antd';
import { RobotOutlined, SaveOutlined } from '@ant-design/icons';
import UserAppAPI from '../../api/UserAppAPI';
import DynamicForm from '../dynamic-form/DynamicForm';
import moment from 'moment';
import CodeEditor from '../CodeEditor';

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const ScriptCodeEditor = ({ appId, script }) => {
    const [code, setCode] = useState(script.code || '');
    const [isEditing, setIsEditing] = useState(false);
    const [aiEditModalVisible, setAiEditModalVisible] = useState(false);
    const [aiEditPrompt, setAiEditPrompt] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const handleCodeChange = (newCode) => {
        setCode(newCode);
        setIsEditing(true);
    };

    const handleSave = async () => {
        setIsSaving(true);
        try {
            await UserAppAPI.manualEditScriptCode(appId, script.id, code);
            message.success('Script code saved successfully');
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving script code:', error);
            message.error('Failed to save script code');
        } finally {
            setIsSaving(false);
        }
    };

    const handleAiEdit = async () => {
        setIsSaving(true);
        try {
            const updatedApp = await UserAppAPI.aiEditScriptCode(appId, script.id, aiEditPrompt);
            message.success('Script code updated successfully');
            const updatedScript = updatedApp.scheduled_actions.find(s => s.id === script.id);
            setCode(updatedScript.code);
            setAiEditModalVisible(false);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating script code:', error);
            message.error('Failed to update script code');
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            <div style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
                {isEditing && (
                    <Button
                        icon={<SaveOutlined />}
                        onClick={handleSave}
                        loading={isSaving}
                        style={{ marginRight: 8 }}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                )}
                <Button
                    icon={<RobotOutlined />}
                    onClick={() => setAiEditModalVisible(true)}
                >
                    AI Edit
                </Button>
            </div>
            <CodeEditor
                value={code}
                onChange={handleCodeChange}
                language="python"
                readOnly={false}
            />
            <Modal
                title="AI Edit Script"
                visible={aiEditModalVisible}
                onOk={handleAiEdit}
                onCancel={() => setAiEditModalVisible(false)}
                confirmLoading={isSaving}
            >
                <Input.TextArea
                    value={aiEditPrompt}
                    onChange={(e) => setAiEditPrompt(e.target.value)}
                    placeholder="Describe how you want to change the code..."
                    rows={4}
                />
            </Modal>
        </div>
    );
};

const HistoricalRuns = ({ appId, scriptId, refreshTrigger }) => {
    const [runs, setRuns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [selectedRun, setSelectedRun] = useState(null);

    useEffect(() => {
        const fetchRuns = async () => {
            setLoading(true);
            try {
                const response = await UserAppAPI.listScriptRuns(appId, scriptId);
                setRuns(response);
            } catch (error) {
                console.error('Error fetching historical runs:', error);
                message.error('Failed to fetch historical runs');
            } finally {
                setLoading(false);
            }
        };

        fetchRuns();
    }, [appId, scriptId, refreshTrigger]);

    if (loading) return <Spin />;

    const showDetails = (run) => {
        setSelectedRun(run);
        setDetailsModalVisible(true);
    };

    const renderOutput = (output) => {
        if (output === null || output === undefined || (typeof output === 'object' && Object.keys(output).length === 0)) {
            return <Tag color="gray">Empty</Tag>;
        }
        if (typeof output === 'string') {
            return output;
        } else if (typeof output === 'object') {
            return JSON.stringify(output, null, 2);
        }
        return '';
    };

    const isInputEmpty = (input) => {
        return input === null || input === undefined || (typeof input === 'object' && Object.keys(input).length === 0);
    };

    return (
        <>
            <List
                dataSource={runs}
                renderItem={(run) => (
                    <List.Item>
                        <List.Item.Meta
                            title={
                                <Space>
                                    <Text>{moment(run.created_date).fromNow()}</Text>
                                    <Tag color={run.results.errors ? 'red' : 'green'}>
                                        {run.results.errors ? 'Error' : 'Success'}
                                    </Tag>
                                </Space>
                            }
                            description={
                                <>
                                    {!isInputEmpty(run.input_values) && (
                                        <>
                                            <Text>Input: {JSON.stringify(run.input_values)}</Text>
                                            <br />
                                        </>
                                    )}
                                    <Text>
                                        Output: {
                                            <Text ellipsis={{ tooltip: renderOutput(run.results.output) }}>
                                                {renderOutput(run.results.output)}
                                            </Text>
                                        }
                                    </Text>
                                </>
                            }
                        />
                        <Button onClick={() => showDetails(run)}>Details</Button>
                    </List.Item>
                )}
            />
            <Modal
                title="Run Details"
                visible={detailsModalVisible}
                onOk={() => setDetailsModalVisible(false)}
                onCancel={() => setDetailsModalVisible(false)}
                width={800}
            >
                {selectedRun && (
                    <>
                        <Paragraph>
                            <Text strong>Time:</Text> {moment(selectedRun.created_date).format('MMMM Do YYYY, h:mm:ss a')}
                        </Paragraph>
                        <Paragraph>
                            <Text strong>Status:</Text> {selectedRun.results.errors ? 'Error' : 'Success'}
                        </Paragraph>
                        {!isInputEmpty(selectedRun.input_values) && (
                            <Paragraph>
                                <Text strong>Input:</Text>
                                <pre>{JSON.stringify(selectedRun.input_values, null, 2)}</pre>
                            </Paragraph>
                        )}
                        <Paragraph>
                            <Text strong>Output:</Text>
                            {renderOutput(selectedRun.results.output) || <Empty description="No output" />}
                        </Paragraph>
                        {selectedRun.results.errors && (
                            <Paragraph>
                                <Text strong>Errors:</Text>
                                <pre>{selectedRun.results.errors}</pre>
                            </Paragraph>
                        )}
                        {selectedRun.results.stdout && (
                            <Paragraph>
                                <Text strong>Standard Output:</Text>
                                <pre>{selectedRun.results.stdout}</pre>
                            </Paragraph>
                        )}
                    </>
                )}
            </Modal>
        </>
    );
};


export default function AppScript({ appId, script }) {
    const [runLoading, setRunLoading] = useState(false);
    const [result, setResult] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [formModalVisible, setFormModalVisible] = useState(false);
    const [lastInputs, setLastInputs] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const handleOpenFormModal = () => {
        setFormModalVisible(true);
    };

    const handleRunScript = async (inputs) => {
        setRunLoading(true);
        setFormModalVisible(false);
        setModalVisible(true);
        setLastInputs(inputs);
        try {
            const response = await UserAppAPI.runScript(appId, script.id, inputs);
            setResult(response.results);
            setRefreshTrigger(prev => prev + 1);
        } catch (error) {
            console.error('Error running script:', error);
            setResult({ error: 'An error occurred while running the script.' });
        } finally {
            setRunLoading(false);
        }
    };

    const handleFixScript = async () => {
        if (!result || !result.errors || !lastInputs) {
            message.error('Unable to fix script: missing error information or inputs');
            return;
        }

        setRunLoading(true);
        try {
            const fixMessage = `I've ran the script with the following inputs: ${JSON.stringify(lastInputs)} and got the following errors: ${result.errors}, please help me fix it`;
            await UserAppAPI.aiEditScriptCode(appId, script.id, fixMessage);
            message.success('Script has been updated. Please try running it again.');
            setModalVisible(false);
        } catch (error) {
            console.error('Error fixing script:', error);
            message.error('An error occurred while trying to fix the script.');
        } finally {
            setRunLoading(false);
        }
    };

    const generateSchema = () => {
        const schema = {
            type: 'object',
            properties: {}
        };

        if (script.script_inputs) {
            script.script_inputs.forEach(input => {
                schema.properties[input.name] = {
                    type: 'string',
                    title: input.name,
                    description: input.description,
                    default: input.example
                };
            });
        }

        return schema;
    };

    const renderResult = () => {
        if (!result) return null;

        if (typeof result === 'object') {
            return (
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    {Object.entries(result).map(([key, value]) => (
                        <div key={key} style={{ marginBottom: '10px' }}>
                            <Text strong>{key}:</Text>
                            <Paragraph pre ellipsis={{ rows: 3, expandable: true, symbol: 'more' }} className='whitespace-pre-wrap break-words'>
                                {typeof value === 'string' ? value : JSON.stringify(value, null, 2)}
                            </Paragraph>
                        </div>
                    ))}
                    {result.errors && (
                        <Button onClick={handleFixScript} type="primary" danger>
                            Fix Script
                        </Button>
                    )}
                </div>
            );
        } else {
            return (
                <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}>
                    {result}
                </Paragraph>
            );
        }
    };

    return (
        <Card key={script.id} className="mb-4" hoverable>
            <div className="flex justify-between items-center mb-4">
                <Title level={4} style={{ marginBottom: 0 }}>{script.title}</Title>
                <Button 
                    onClick={handleOpenFormModal} 
                    type="primary"
                    loading={runLoading}
                    disabled={!script.code}
                >
                    Run Script
                </Button>
            </div>
            <Paragraph>{script.description}</Paragraph>
            {script.schedule && (
                <Paragraph><strong>Schedule:</strong> {script.schedule}</Paragraph>
            )}
            <Paragraph>
                <strong>Libraries:</strong>{' '}
                {script.libraries && script.libraries.map((lib, index) => (
                    <Tag key={index} color="blue">{lib}</Tag>
                ))}
            </Paragraph>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Historical Runs" key="1">
                    <HistoricalRuns appId={appId} scriptId={script.id} refreshTrigger={refreshTrigger} />
                </TabPane>
                {script.script_inputs && script.script_inputs.length > 0 && (
                    <TabPane tab="Script Inputs" key="2">
                        <List
                            itemLayout="horizontal"
                            dataSource={script.script_inputs}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<Text strong>{item.name}</Text>}
                                        description={
                                            <>
                                                <Paragraph>{item.description}</Paragraph>
                                                <Text type="secondary">Example: {item.example}</Text>
                                            </>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </TabPane>
                )}
                <TabPane tab="View Code" key="3">
                    <ScriptCodeEditor
                        appId={appId}
                        script={script}
                    />
                </TabPane>
            </Tabs>
            <Modal
                title="Run Script"
                open={formModalVisible}
                onCancel={() => setFormModalVisible(false)}
                footer={null}
            >
                <DynamicForm
                    schema={generateSchema()}
                    onSubmit={handleRunScript}
                    initialValues={script.script_inputs ? script.script_inputs.reduce((acc, input) => {
                        acc[input.name] = input.example;
                        return acc;
                    }, {}) : {}}
                />
            </Modal>
            <Modal
                title="Script Result"
                open={modalVisible}
                onOk={() => setModalVisible(false)}
                onCancel={() => setModalVisible(false)}
                width={600}
            >
                {runLoading ? (
                    <Spin size="large" />
                ) : (
                    renderResult()
                )}
            </Modal>
        </Card>
    );
}