import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { Form, Input, Button, Typography, Layout, message } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import AuthAPI from '../api/AuthAPI';

const { Title, Text } = Typography;
const { Content } = Layout;

export default function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      // Assuming AuthAPI has a register method
      await AuthAPI.create(values);
      // If registration is successful, log the user in
      const success = await login(values.email, values.password);
      if (success) {
        navigate('/');
      } else {
        message.warning('Registration successful, but login failed. Please try logging in.');
      }
    } catch (error) {
      console.error('Registration failed:', error, error.response.data.detail);
      if (error.response && error.response.data && (error.response.data.detail || error.response.data.detail)) {
        const msg = error.response.data.detail || error.response.data.detail;
        message.error(`Registration failed: ${msg}`);
      } else {
        message.error('Registration failed. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout className="min-h-screen">
      <Content className="flex justify-center items-center">
        <div className="w-80 p-6 bg-white rounded-lg shadow-md">
          <Title level={2} className="text-center mb-6">Register</Title>
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email address!' }
              ]}
            >
              <Input
                prefix={<MailOutlined className="text-gray-400" />}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined className="text-gray-400" />}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords do not match!'));
                  },
                }),
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="text-gray-400" />}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                size="large"
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" className="w-full" loading={loading}>
                Register
              </Button>
            </Form.Item>
          </Form>
          <Text className="block text-center mt-4">
            Already have an account? <Link to="/login">Log in</Link>
          </Text>
        </div>
      </Content>
    </Layout>
  );
}