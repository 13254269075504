import React, { useState, useEffect } from 'react';
import { Typography, Table, Tabs, Button, Modal, Popconfirm, message, Card, Row, Col, List } from 'antd';
import { entitiesFromApp } from '../../entities/entities';
import DynamicForm from '../dynamic-form/DynamicForm';
import ReadableJSONSchema from '../ReadableJSONSchema';

const { Paragraph } = Typography;
const { TabPane } = Tabs;


function EntityRecords({ entities, entity, onAddNewItem, onEditItem, onDeleteItem }) {
    const columns = Object.entries(entity.json_schema.properties).map(([name, prop]) => ({
        title: name.charAt(0).toUpperCase() + name.slice(1),
        dataIndex: name,
        key: name,
        ellipsis: true,
    }));

    columns.push({
        title: 'Actions',
        key: 'actions',
        fixed: 'right',
        width: 120,
        render: (text, record) => (
            <span>
                <Button type="link" onClick={() => onEditItem(record)}>Edit</Button>
                <Popconfirm
                    title="Are you sure delete this item?"
                    onConfirm={() => onDeleteItem(record)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button type="link" danger>Delete</Button>
                </Popconfirm>
            </span>
        ),
    });

    return (
        <Table 
            dataSource={entities}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{ x: 'max-content' }}
        />
    );
}

function EntityView({ app, entity }) {
    const [entities, setEntities] = useState([]);
    const [errors, setErrors] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingItem, setEditingItem] = useState(null);

    const entitiesSDK = entitiesFromApp(app, (error) => {
        setErrors(prevErrors => [...prevErrors, error]);
    });

    useEffect(() => {
        entitiesSDK[entity.name].list().then((entities) => {
            setEntities(entities);
        });
    }, []);

    const handleCreateNewItem = async (values) => {
        try {
            const newItem = await entitiesSDK[entity.name].create(values);
            setEntities([...entities, newItem]);
            setIsModalVisible(false);
        } catch (error) {
            setErrors([...errors, error]);
        }
    };

    const handleEditItem = (item) => {
        setEditingItem(item);
        setIsModalVisible(true);
    };

    const handleUpdateItem = async (values) => {
        try {
            const updatedItem = await entitiesSDK[entity.name].update(editingItem.id, values);
            setEntities(entities.map(item => item.id === editingItem.id ? updatedItem : item));
            setIsModalVisible(false);
            setEditingItem(null);
        } catch (error) {
            setErrors([...errors, error]);
        }
    };

    const handleDeleteItem = async (item) => {
        try {
            await entitiesSDK[entity.name].delete(item.id);
            setEntities(entities.filter(entity => entity.id !== item.id));
            message.success('Item deleted successfully');
        } catch (error) {
            setErrors([...errors, error]);
            message.error('Failed to delete item');
        }
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card 
                        title={
                            <div className="py-4">
                                {entity.name}
                                <Paragraph style={{ color: 'grey', fontSize: 'small', margin: 0 }}>
                                    {entity.description}
                                </Paragraph>
                            </div>
                        } 
                        bordered={false} 
                        className="mb-4"
                    >
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Entity Definition" key="1">
                                <ReadableJSONSchema schema={entity.json_schema} />
                            </TabPane>
                            <TabPane tab="Records" key="2">
                                <div style={{ overflowX: 'auto' }}>
                                    <EntityRecords 
                                        entities={entities} 
                                        entity={entity} 
                                        onAddNewItem={() => setIsModalVisible(true)} 
                                        onEditItem={handleEditItem}
                                        onDeleteItem={handleDeleteItem}
                                    />
                                </div>
                                <Button 
                                    type="primary" 
                                    className="mt-6"
                                    onClick={() => setIsModalVisible(true)}
                                >
                                    Add New Item
                                </Button>
                            </TabPane>
                        </Tabs>
                    </Card>
                </Col>
            </Row>
            <Modal
                title={editingItem ? `Edit ${entity.name}` : `Add New ${entity.name}`}
                open={isModalVisible}
                onCancel={() => {
                    setIsModalVisible(false);
                    setEditingItem(null);
                }}
                footer={null}>
                <DynamicForm 
                    schema={entity.json_schema} 
                    onSubmit={editingItem ? handleUpdateItem : handleCreateNewItem} 
                    initialValues={editingItem}
                />
            </Modal>
        </>
    );
}

export default EntityView;