import React from 'react';
import { Modal, Form, Input, Select, Button, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import UserAppAPI from '../../api/UserAppAPI';

const { Option } = Select;

const InviteUserModal = ({ isVisible, onCancel, appId, onInviteSuccess }) => {
  const [form] = Form.useForm();

  const handleInvite = async (values) => {
    try {
      await UserAppAPI.inviteUser(appId, values.email, values.role);
      message.success('Invitation sent successfully');
      form.resetFields();
      onInviteSuccess();
      onCancel();
    } catch (error) {
      message.error('Failed to send invitation');
    }
  };

  return (
    <Modal
      title="Invite User"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
    >
      <Form form={form} onFinish={handleInvite} layout="vertical">
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
        >
          <Input prefix={<MailOutlined />} placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          name="role"
          label="Role"
          rules={[{ required: true, message: 'Please select a role!' }]}
        >
          <Select placeholder="Select a role">
            <Option value="user">User</Option>
            <Option value="admin">Admin</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Send Invitation
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteUserModal;
