import { Card, Typography } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;


function AppCard({ app }) {
    const navigate = useNavigate();
    return <Card 
            hoverable
            onClick={() => navigate(`/user-apps/${app.id}/dashboard`)}
            className="h-full shadow-md transition-all duration-300 hover:shadow-lg">
            <div className="flex items-center mb-4">
                {app.logo_url && (
                    <div className="mr-4">
                        <img 
                            src={app.logo_url} 
                            alt={`${app.name} Logo`} 
                            className="h-14 w-14 rounded-full object-cover"
                        />
                    </div>
                )}
                <Title level={4} className="text-lg md:text-xl m-0" style={{marginBottom: 0}}>{app.name}</Title>
            </div>
            <Paragraph ellipsis={{ rows: 2 }} className="text-sm md:text-base mb-4">{app.user_description || 'No description available'}</Paragraph>
            <Paragraph type="secondary" className="text-xs md:text-sm">
                {moment(app.created_date).fromNow()} by {app.created_by}
            </Paragraph>
        </Card>
}

export default AppCard;