import { Button, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { LiveProvider, LiveEditor, LivePreview, LiveError, withLive } from "react-live";
import * as ReactLeaflet from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import * as antd from 'antd';
import * as AntdIcons from '@ant-design/icons';
import * as d3 from 'd3';
import moment from 'moment';

const { Tabs } = antd;

const BrowserWindow = ({ path, children }) => {
  return (
    <div className="w-full mx-auto overflow-hidden rounded-lg shadow-lg bg-white">
      <div className="bg-gray-200 px-4 py-2 flex items-center">
        <div className="flex space-x-2">
          <div className="w-3 h-3 bg-red-500 rounded-full"></div>
          <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
          <div className="w-3 h-3 bg-green-500 rounded-full"></div>
        </div>
        <div className="ml-4 flex-grow">
          <div className="bg-white w-full px-3 py-1 rounded text-sm text-gray-600">
            {path}
          </div>
        </div>
      </div>
      <div className="p-4">
        {children}
      </div>
    </div>
  );
};

function LiveErrorInnerComponentInner({makeChanges, live}) {
  const { error } = live;
  const [isFixing, setIsFixing] = useState(false);

  const fixCode = async () => {
    setIsFixing(true);
    try {
      await makeChanges(`I get the following error: ${error.toString()}. Please fix it`);
    } finally {
      setIsFixing(false);
    }
  }

  return error && (
    <div className="mt-4 p-4 bg-red-100 border border-red-300 rounded-md">
      <p className="text-red-700 font-semibold mb-2">Error:</p>
      <p className="text-red-600 mb-4">{error.toString()}</p>
      <Button 
        type="primary" 
        danger 
        onClick={fixCode} 
        disabled={isFixing}
        icon={isFixing ? <Spin size="small" /> : <AntdIcons.ToolOutlined />}
      >
        {isFixing ? 'Fixing...' : 'Fix Error'}
      </Button>
    </div>
  );
}

let _id = 0;

export default function ComponentPreview({componentCode, componentName, componentProps, makeChanges}) {
    window._componentProps = componentProps;
    useEffect(() => {
      eval(componentCode);
      window.renderComponent(`root-page`, componentProps);
      // _id++;
    }, []);

    return (
      <div id="component-preview">
        <div id="root-page"></div>
      </div>
    );
  
}
