import { useState, useEffect } from 'react';
import { List, Input, Button, Spin, Alert, message as antdMessage } from 'antd';
import { SendOutlined, LoadingOutlined, ToolOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import Loader from '../Loader';


const MessageMarkdown = ({ children }) => {
    return <ReactMarkdown className="chat-markdown" remarkPlugins={[remarkGfm]}>{children}</ReactMarkdown>;
}

function ToolCall({ toolCall }) {
    const [expanded, setExpanded] = useState(false);

    const formatArguments = (args) => {
        if (typeof args === 'object' && args !== null) {
            return Object.entries(args).map(([key, value]) => {
                const stringValue = typeof value === 'object' ? JSON.stringify(value) : value.toString();
                const truncatedValue = stringValue.length > 100 && !expanded ? stringValue.slice(0, 97) + '...' : stringValue;
                return (
                    <div key={key}>
                        <span className="font-semibold">{key}:</span>{' '}
                        {truncatedValue.split('\n').map((line, i) => (
                            <span key={i}>
                                {i > 0 && <br />}
                                {line}
                            </span>
                        ))}
                    </div>
                );
            });
        }
        return args;
    };

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="tool-call bg-blue-50 p-3 rounded-md mt-2 mb-4 border border-blue-200">
            <ToolOutlined className="mr-2 text-blue-500" />
            <span className="font-semibold text-blue-700">{toolCall.name}</span>
            <div className="ml-2 text-gray-700">
                {formatArguments(toolCall.arguments)}
            </div>
            {Object.values(toolCall.arguments).some(value => value.toString().length > 100) && (
                <Button 
                    type="link" 
                    onClick={toggleExpand} 
                    icon={expanded ? <UpOutlined /> : <DownOutlined />}
                    className="text-blue-600 hover:text-blue-800"
                >
                    {expanded ? 'Show less' : 'Show more'}
                </Button>
            )}
        </div>
    );
}

function ToolResults({ content }) {
    const [expanded, setExpanded] = useState(false);

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    const truncatedContent = content.length > 100 && !expanded
        ? content.slice(0, 97) + '...'
        : content;

    return (
        <div className="tool-results bg-green-50 p-3 rounded-md my-2 border border-green-200">
            <div className="font-semibold mb-1 text-green-700">Results:</div>
            <div className="text-gray-700">
                {truncatedContent.split('\n').map((line, i) => (
                    <span key={i}>
                        {i > 0 && <br />}
                        {line}
                    </span>
                ))}
            </div>
            {content.length > 100 && (
                <Button 
                    type="link" 
                    onClick={toggleExpand} 
                    icon={expanded ? <UpOutlined /> : <DownOutlined />}
                    className="text-green-600 hover:text-green-800"
                >
                    {expanded ? 'Show less' : 'Show more'}
                </Button>
            )}
            <div className="text-xs text-gray-500 mt-1">
                (This is the result of a tool execution, not the AI's response)
            </div>
        </div>
    );
}

function Chat({chat, addMessage, status}) {
    const [message, setMessage] = useState('');
    const [messageApi, contextHolder] = antdMessage.useMessage();

    const handleSend = async () => {
        if (message.trim() && status.state !== "processing") {
            try {
                const newMessage = {
                    content: message
                }
                
                setMessage('');
                await addMessage(newMessage);
            } catch (err) {
                console.error(err);
                messageApi.error("Failed to send message");
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    if (status.state === "error") return <Alert message={status.details} type="error" className="m-4" />;
    if (!chat) return <Alert message="No chat found" type="warning" className="m-4" />;

    return (
        <div className="flex flex-col bg-gray-50 h-full border border-gray-200">
            <List
                className="flex-grow overflow-auto p-4"
                itemLayout="horizontal"
                dataSource={chat.messages}
                renderItem={(item) => (
                    <List.Item className={`flex ${item.role === 'user' ? 'justify-end' : item.role === 'tool' ? 'justify-start' : 'justify-start'}`}>
                        <div className={`flex flex-col items-start max-w-3/4`}>
                            <span className="text-xs text-gray-500 mb-1">{item.role}</span>
                            {item.role === 'tool' ? (
                                <div className="px-2 py-3 rounded-lg bg-gray-100">
                                    <ToolResults content={item.content || 'No content'} />
                                </div>
                            ) : (
                                <div className={`px-4 pt-3 rounded-lg shadow-md ${item.role === 'user' ? 'bg-[#1a8a8c] text-white' : 'bg-white'}`}>
                                    <MessageMarkdown>{item.content}</MessageMarkdown>
                                    {item.tool_calls && item.tool_calls.map((toolCall, index) => <ToolCall key={index} toolCall={toolCall} />)}
                                </div>
                            )}
                        </div>
                    </List.Item>
                )}
            />
            {status.state === "processing" && (
                <div className="flex justify-center items-center p-4 bg-yellow-50">
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    {/* <Loader /> */}
                    <span className="ml-2 text-yellow-700">Processing message...</span>
                </div>
            )}
            <div className="p-4 bg-white border-t border-gray-200">
                <Input.Group compact>
                    <Input
                        style={{ width: 'calc(100% - 40px)' }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Type a message..."
                        disabled={status.state === "processing"}
                        className="border-2 border-gray-300 focus:border-[#1a8a8c] hover:border-[#1a8a8c]"
                    />
                    <Button 
                        type="primary" 
                        icon={<SendOutlined />} 
                        onClick={handleSend} 
                        disabled={status.state === "processing"}
                        className="bg-[#1a8a8c] hover:bg-[#147a7c] border-[#1a8a8c] hover:border-[#147a7c]"
                    />
                </Input.Group>
            </div>
        </div>
    );
}

export default Chat;