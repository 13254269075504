import React from 'react';
import { Form, Input, Select, InputNumber, Switch, Button, Space, DatePicker, TimePicker, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Text } = Typography;

// TODO : array is not saving the values

const ArrayFormItem = ({ name, schema, form }) => {
  if (schema.items.type === 'string') {
    return (
      <Select mode="tags" style={{ width: '100%' }} placeholder="Enter items">
        {schema.items.enum && schema.items.enum.map(option => (
          <Select.Option key={option} value={option}>
            {option}
          </Select.Option>
        ))}
      </Select>
    );
  }

  return (
    <Form.List name={name}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <DynamicFormItem
                name={[field.name]}
                schema={schema.items}
                form={form}
              />
              <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Space>
          ))}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add Item
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

const DynamicFormItem = ({ name, schema, required, form }) => {
  const renderField = () => {
    if (schema.enum) {
      return (
        <Select>
          {schema.enum.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
    }

    switch (schema.type) {
      case 'string':
        if (schema.format === 'date') {
          return <DatePicker />;
        } else if (schema.format === 'date-time') {
          return <DatePicker showTime />;
        } else if (schema.format === 'time') {
          return <TimePicker />;
        }
        return <Input />;
      case 'number':
        return <InputNumber min={schema.minimum} max={schema.maximum} />;
      case 'integer':
        return <InputNumber precision={0} min={schema.minimum} max={schema.maximum} />;
      case 'boolean':
        return <Switch />;
      case 'object':
        return Object.entries(schema.properties).map(([key, value]) => (
          <DynamicFormItem
            key={key}
            name={[name, key]}
            schema={value}
            form={form}
          />
        ));
      case 'array':
        return <ArrayFormItem name={name} schema={schema} form={form} />;
      default:
        return null;
    }
  };

  return (
    <Form.Item
      key={name}
      name={name}
      label={
        <div>
          <div>{schema.title || name}</div>
          {schema.description && (
            <Text type="secondary" style={{ display: 'block', fontSize: '12px' }}>
              {schema.description}
            </Text>
          )}
        </div>
      }
      rules={[{ required: required, message: `${schema.title || name} is required` }]}
      getValueFromEvent={(e) => {
        if (schema.type === 'string' && (schema.format === 'date' || schema.format === 'date-time')) {
          return e ? e.format('YYYY-MM-DD' + (schema.format === 'date-time' ? 'THH:mm:ss' : '')) : undefined;
        }
        return e && e.target ? e.target.value : e;
      }}
      getValueProps={(value) => {
        if (schema.type === 'string' && (schema.format === 'date' || schema.format === 'date-time')) {
          return { value: value ? moment(value) : undefined };
        }
        return { value };
      }}
    >
      {renderField()}
    </Form.Item>
  );
};

export default DynamicFormItem;