import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Spin, Button, Tabs, Divider, Image, Space, message } from 'antd';
import { AppstoreOutlined, FileTextOutlined, CodeOutlined, SettingOutlined, ShareAltOutlined, RocketOutlined, LoadingOutlined } from '@ant-design/icons';
import UserAppAPI from '../api/UserAppAPI';
import EntityView from '../components/user-app/EntityView';
import Chat from '../components/chat/Chat';
import AppScript from '../components/user-app/AppScript';
import AppSettings from '../components/user-app/AppSettings';
import InviteUserModal from '../components/user-app/InviteUserModal';
import ReadableJSONSchema from '../components/ReadableJSONSchema';
import { InfoCircleOutlined } from '@ant-design/icons';
import { AppProvider, useApp } from '../context/AppContext';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

function AppHeader({ app, onOpenApp, onShareApp }) {
    return (
        <div className="mb-6">
            <div className="flex items-center mb-2">
                {app.logo_url && (
                    <Image
                        src={app.logo_url}
                        alt={`${app.name} logo`}
                        width={60}
                        height={60}
                        preview={false}
                        className="rounded-full object-cover mr-4"
                    />
                )}
                <Title level={2} className="text-2xl font-bold text-blue-600 m-0 flex items-center"
                    style={{ marginBottom: 0, marginLeft: 20 }}>{app.name}</Title>
            </div>
            <Paragraph className="text-sm text-gray-600 mb-4" ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
                {app.user_description}
            </Paragraph>
            {app.status.state === "processing" ? (
                <Space>
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                    <div className="text-gray-500">{app.status.details}</div>
                </Space>
            ) : (
                <div>
                    <Button 
                        type="primary" 
                        className="mr-2"
                        icon={<RocketOutlined />}
                        onClick={onOpenApp}
                    >
                        Open App
                    </Button>
                    <Button
                        type="default"
                        icon={<ShareAltOutlined />}
                        onClick={onShareApp}
                    >
                        Share App
                    </Button>
                </div>
            )}
        </div>
    );
}

function PageComponent({ page, appId, onCodePage }) {
    const { app } = useApp();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleCodePage = async () => {
        setLoading(true);
        await onCodePage(page.id);
        setLoading(false);
    };

    const handleShowPage = () => {
        navigate(`/user-apps/${appId}/show/${page.name}`);
    };

    return (
        <div key={page.id} className="mb-4">
            <Title level={4}>{page.title}</Title>
            {
                page.url_parameters?.length > 0 && (
                    <div>
                        <Paragraph>URL Parameters: {JSON.stringify(page.url_parameters)}</Paragraph>
                    </div>
                )
            }
            <Paragraph className='whitespace-pre-wrap'>{page.description}</Paragraph>
            {!page.code && (
                <Button 
                    onClick={handleCodePage}
                    loading={loading}
                >
                    Code Page
                </Button>
            )}
            {page.code && (
                <>
                    <Button 
                        disabled={app.status?.state !== "ready"}
                            onClick={handleShowPage} className="mr-2">
                        Show Page
                    </Button>
                    <Button onClick={handleCodePage} loading={loading}
                        disabled={app.status?.state !== "ready"}
                    >
                        Re-implement Page
                    </Button>
                </>
            )}
            <Divider />
        </div>
    );
}

function EntitiesComponent({ app }) {
    return (<div>
            <div className="bg-white rounded-lg shadow-md p-6 mb-4">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-2xl font-semibold">User Data</h3>
                    <div className="group relative">
                        <InfoCircleOutlined className="h-5 w-5 text-gray-500 hover:text-gray-700 cursor-pointer" />
                        <span className="absolute hidden group-hover:block bg-gray-800 text-white text-sm rounded p-2 -mt-2 ml-6">
                            Data stored for each user of the app
                        </span>
                    </div>
                </div>
                <p className="text-gray-600 mb-4">
                    This schema defines the additional information stored for each user of the app.
                </p>
                <div className="mt-4">
                    <ReadableJSONSchema 
                        schema={app.additional_user_data_schema} 
                    />
                </div>
            </div>
            <Divider />
            <div>
                {app.entities.map((entity) => (
                    <EntityView key={entity.name} app={app} entity={entity} />
                ))}
            </div>
        </div>
    );
}

function PagesComponent({ pages, appId, onCodePage }) {
    return (
        <div>
            {pages.map((page) => (
                <PageComponent 
                    key={page.id}
                    page={page}
                    appId={appId}
                    onCodePage={onCodePage}
                />
            ))}
        </div>
    );
}

function ScriptsComponent({ appId, scripts }) {
    return (
        <div>
            {scripts.map((script) => (
                <AppScript appId={appId} script={script} />
            ))}
        </div>
    );
}

function UserAppDashboard() {
    const { app, setApp, addChatMessage } = useApp();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleCodePage = async (pageId) => {
        try {
            const updatedApp = await UserAppAPI.codePage(app.id, pageId);
            setApp(updatedApp);
        } catch (err) {
            console.error(err);
            message.error('Failed to code page: ' + err.message);
        }
    };

    const handleOpenApp = () => {
        window.open(`/user-apps/${app.id}/show`, '_blank');
    };

    const handleShareApp = () => {
        setIsInviteModalVisible(true);
    };

    const handleInviteSuccess = () => {
        // You can add any additional logic here after a successful invitation
    };

    const hasEntitiesOrPagesOrScripts = app.entities.length > 0 || app.pages.length > 0 || app.scheduled_actions.length > 0;

    return (
        <div className={`${isMobile ? 'px-2' : 'p-6'} flex ${isMobile ? 'flex-col' : 'flex-row'}`}>
            {hasEntitiesOrPagesOrScripts ? (
                <>
                    <div className={isMobile ? 'w-full mb-6' : 'w-2/3 pr-6'}>
                        <AppHeader 
                            app={app}
                            onOpenApp={handleOpenApp}
                            onShareApp={handleShareApp}
                        />
                        <Tabs defaultActiveKey="1" className="mt-4">
                            <TabPane tab={<span><FileTextOutlined />Pages ({app.pages.length})</span>} key="1">
                                <PagesComponent pages={app.pages} appId={app.id} onCodePage={handleCodePage} />
                            </TabPane>
                            <TabPane tab={<span><AppstoreOutlined />Entities ({app.entities.length})</span>} key="2">
                                <EntitiesComponent app={app} entities={app.entities} />
                            </TabPane>
                            <TabPane tab={<span><CodeOutlined />Scripts ({app.scheduled_actions.length})</span>} key="3">
                                <ScriptsComponent scripts={app.scheduled_actions} appId={app.id} />
                            </TabPane>
                            <TabPane tab={<span><SettingOutlined />Settings</span>} key="4">
                                <AppSettings app={app} />
                            </TabPane>
                        </Tabs>
                    </div>
                    <div className={isMobile ? 'w-full' : 'w-1/3 pl-3'}>
                        <Chat chat={app.conversation} addMessage={addChatMessage} status={app.status} />
                    </div>
                </>
            ) : (
                <div className="w-full flex justify-center">
                    <div className="max-w-4xl w-full">
                        <Chat chat={app.conversation} addMessage={addChatMessage} status={app.status} />
                    </div>
                </div>
            )}
            <InviteUserModal
                isVisible={isInviteModalVisible}
                onCancel={() => setIsInviteModalVisible(false)}
                appId={app.id}
                onInviteSuccess={handleInviteSuccess}
            />
        </div>
    );
}

export default () => (
    <AppProvider>
        <UserAppDashboard />
    </AppProvider>
);
