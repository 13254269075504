import React, { createContext, useState, useContext, useEffect } from 'react';
import { Spin, Alert } from 'antd';
import UserAppAPI from '../api/UserAppAPI';
import { useParams } from 'react-router-dom';
import { entitiesFromApp } from '../entities/entities';
import createIntegrationsClient from '../api/Integrations';
import RegisterToApp from '../components/user-app/RegisterToApp';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const {appId} = useParams();
    const [app, setApp] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [integrations, setIntegrations] = useState(null);
    const [entitiesSDK, setEntitiesSDK] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [error, setError] = useState(null);
    const [appErrors, setAppErrors] = useState([]);
    const [showRegister, setShowRegister] = useState(false);
    const [registerInfo, setRegisterInfo] = useState(null);
    const onAppError = (error) => {
        setAppErrors(prevErrors => {
            if (!prevErrors.some(e => e.message === error.message)) {
                return [...prevErrors, error];
            }
            return prevErrors;
        });
    }

    const init = async () => {
        try {
          setIsLoading(true);
          await UserAppAPI.subscribe(appId, (fetchedApp) => {
              setApp(fetchedApp);
              const appEntitiesSDK = entitiesFromApp(fetchedApp, onAppError);
              setEntitiesSDK(appEntitiesSDK);
          });
  
          // TODO: subscribe to user info.
          const fetchedUserInfo = await UserAppAPI.getMyUserInfo(appId);
          setUserInfo(fetchedUserInfo);
  
          const integClient = await createIntegrationsClient('/integrations', onAppError);
          setIntegrations(integClient);
  
          
      } catch (err) {
          if (err.response && err.response.status === 403) {
              setShowRegister(true);
              setRegisterInfo(err.response.data.detail);
          } else {
              setError('Failed to load app: ' + err.message);
          }
      } finally {
          setIsLoading(false);
        }
      };

    
    useEffect(() => {
        init();
    }, [appId]);

  const updateUserInfo = async (userId, newInfo) => {
    try {
      const updatedUserInfo = await UserAppAPI.updateUserData(appId, userId, newInfo);
      setUserInfo(updatedUserInfo);
    } catch (err) {
      setError(err.message);
    }
  };

  const addChatMessage = async (message) => {
    try {
        app.conversation.messages.push(message);
        const updatedApp = await UserAppAPI.addMessage(appId, message);
        setApp(updatedApp);
    } catch (err) {
        console.error(err);
        setError('Failed to add message: ' + err.message);
    }
  };

  let element = children

  if (showRegister) {
    element = <RegisterToApp 
        appId={appId}
        appName={registerInfo.app_name}
        appDescription={registerInfo.app_description}
        logoUrl={registerInfo.app_logo}
        onRegister={() => init()}
        />;
    } else if (isLoading) {
        element = <Spin className="flex justify-center items-center h-screen" />;
    } else if (error) {
        element = <Alert message={error} type="error" className="m-4" />;
    } else if (!app) {
        element =  <Alert message="App not found" type="error" className="m-4" />;
    }



  return (
    <AppContext.Provider value={{
        app,
        setApp,
        isLoading,
        integrations,
        entitiesSDK,
        userInfo,
        updateUserInfo,
        error,
        appErrors,
        onAppError,
        addChatMessage,
      }}>
        {element}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);