import { List } from 'antd';

export default function ReadableJSONSchema({ schema }) {
    return (
        <List
            itemLayout="vertical"
            dataSource={Object.entries(schema.properties)}
            renderItem={([name, prop]) => (
                <List.Item>
                    <List.Item.Meta
                        title={
                            <div>
                                <b>{name}</b>
                                {schema.required && schema.required.includes(name) && (
                                    <span style={{ color: 'grey', fontSize: 'small' }}> (required)</span>
                                )}
                            </div>
                        }
                        description={
                            <div>
                                <div style={{ fontSize: 'small', color: 'grey' }}>
                                    <b>Type:</b> {prop.type}
                                </div>
                                {prop.description}
                            </div>
                        }
                    />
                    {prop.default !== undefined && (
                        <div><b>Default:</b> {JSON.stringify(prop.default)}</div>
                    )}
                    {Object.entries(prop).filter(([key]) => !['type', 'description', 'default'].includes(key)).length > 0 && (
                        <div>
                            <b>Additional Properties:</b>
                            <ul>
                                {Object.entries(prop).filter(([key]) => !['type', 'description', 'default'].includes(key)).map(([key, value]) => (
                                    <li key={key}>
                                        {key}: {JSON.stringify(value)}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </List.Item>
            )}
        />
    );
}